@import '~include-media/dist/_include-media.scss';
// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,semibold,500,italic&subset=latin,latin-ext');
// @import url('https://fonts.googleapis.com/css?family=Audiowide:300,400&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Abel:300,400&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Glegoo:300,400,500,italic&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lora:regular&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Nunito:400,italic&display=swap&subset=latin,latin-ext');

$fa-font-path: "~font-awesome/fonts";
@import '~font-awesome/scss/font-awesome.scss';

$breakpoints: (phone: 768px, laptop: 1024px, desktop: 1378px);

$link-color: #88b14b;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  // height: 100%;
  font-family: 'Nunito';
  overflow-x: hidden;
}

html {
  overflow-y: scroll;
  font-size: 1.12em;
}

a {
  // font-family: 'Source Sans Pro', sans-serif;
  color: $link-color;
  text-decoration: none;
  // font-weight: 500;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  &:active {
    color: #014c9c;
    text-decoration: underline;
  }
}

.delayed-content {
  overflow: hidden;
  max-height: auto;
  transition: all 1.5s ease-in-out;
  padding-top: 4rem;

  @include media("<=phone") {
    padding-top: 2rem;
  }

  &.locked {
    max-height: 0;
    padding-top: 0;
  }
}

.hidden {
  opacity: 0 !important;
}

.main-canvas {
  // width: 100%;
  // height: 100%;
  // /* height: 400px; */
  // position: fixed;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  // display: none;
  pointer-events: none;
  z-index: 1000;
}

// .content {
//   //
// }

.html-render-container {
  position: absolute;
  width: fit-content;
  left: 200vw;
  // left: 0;
  // top: 0;
  z-index: 100;
}

.swarm-target {
  display: inline-block;
  width: fit-content;
  height: fit-content;
  // flex: content;
  // width: fit-content;
  // height: fit-content;
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;

  @include media("<=phone") {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @include media(">phone", "<=laptop") {
    width: 700px;
  }
  @include media(">laptop", "<=desktop") {
    width: 960px;
  }
  @include media(">desktop") {
    width: 1200px;
  }
}

.hero {
  position: relative;
  width: 100%;
  height: 100vh;
  padding-top: 35vh;

  .proceed {
    position: absolute;
    left: 50%;
    bottom: 1rem;
    margin-left: -1rem;
    opacity: 0.4;
    transition: opacity 0.5s cubic-bezier(0.525, 0.400, 0.650, 1.650);

    @include media("<=phone") {
      bottom: 4rem;
    }

    .fa {
      color: $link-color;
      font-size: 3rem;
    }
  }
}

.welcome {
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  font-size: .9rem;
  text-align: center;
  line-height: 1.5;
  // font-family: 'Glegoo';
  font-family: 'Lora';

  .welcome-line {
    opacity: 1;
    transition: opacity 1.0s ease-in-out;
  }
}

// .logo {
//   width: 200px;
//   height: 200px;
//   // margin-bottom: 1rem;
// }

.project-group-header {
  margin-top: 2rem;
  margin-bottom: 3rem;
  color: #ddd;
  font-family: 'Lora';
  font-size: 6rem;
  text-transform: uppercase;
  letter-spacing: 1.0rem;
  word-break: break-all;

  @include media("<=phone") {
    // letter-spacing: 0;
    // font-size: 5rem;
  }
}

.project {
  margin-bottom: 5rem;
}

.project-name {
  // display: inline-block;
  width: fit-content;
  // font-family: 'Abel', sans-serif;
  font-family: 'Lora';
  font-size: 2.2rem;
  // font-weight: 400;
  margin-bottom: 0.75rem;
  // text-decoration: underline;
  // border-bottom: solid 0.1rem #000;

  // &.excog {
  //   font-family: 'Abel', sans-serif;
  // }
  // &.eso {
  //   font-family: 'Source Sans Pro', sans-serif;
  // }
}

.project-desc {
  // font-family: 'Glegoo', sans-serif;
  font-size: 1rem;
  // font-weight: 300;
}

.project-tech {
  color: #888;
  margin-top: .5rem;
  font-size: .9rem;

  .tech-list {
    font-style: italic;

    .item {
      display: inline-block;
      margin-right: 1rem;
      white-space: nowrap;
      // line-height: 1.5;
    }
  }
}

.project-links {
  font-size: 1rem;
  margin-top: .75rem;

  a {
    margin-right: 1rem;
  }
}

.project-preview-media {
  padding: 0 !important;

  video, 
  img {
    width: 100%;
    display: block;
    margin-top: 2.5rem;
    margin-bottom: 4rem;
  }

  .shadow {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);

    @include media("<=phone") {
      display: block;
    }
  }
}

// #project-symbol-l2mapper {
//   & > div {
//     @include media("<=phone") {
//       width: 350px !important;
//       height: 265px !important;
//     }
//   }
// }

#epilogue {
  display: block;
  width: fit-content;
  margin: 2rem auto;
}

.footer {
  margin-top: 4em;
  margin-bottom: 4em;
  text-align: center;

  a {
    color: #ccc;

    &:hover {
      color: #ddd;
    }

    .fa {
      font-size: 2rem;
    }
  }
}
